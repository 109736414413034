import jwtDecode from "jwt-decode";
import FeaturedInfo from "../../components/featuredInfo/FeaturedInfo";
import FirstWidget from "../../components/FirstWidget/FirstWidget";
import ReportList from "../../components/report/ReportList";
import WidgetLeft from "../../components/widgetlLeftChat/WidgetLeft";
import WidgetRight from "../../components/wigdetRightChat/WidgetRight";
import "./dashboard.scss";
import Image1 from "../../assets/images/cons.png";
import Image2 from "../../assets/images/msg.png";
import commaNumber from "comma-number";
import { BASE_URL } from "../../context/base/base";
import axios from "axios";
import jwt from "jwt-decode";
import { useQuery } from "@tanstack/react-query";
import { Loader } from "../../components/Loader/Loader";
import { toast } from "react-toastify";
import ReportTable from "../../components/ReportTable/ReportTable";

export default function Dashboard() {
  const userDetail = localStorage.getItem("user");
  const user = JSON.parse(userDetail);

  const token = user?.access_token;

  if (token) {
    const decodedToken = jwtDecode(token);
    if (decodedToken.exp * 1000 < new Date().getTime()) {
      localStorage.removeItem("user");
      window.location.href = "/login";
    }
  }

  const api = `${BASE_URL}/auth/sms_consumption_count`;

  const getUnits = async () => {
    const res = await axios.get(api, {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Frame-Options": "deny",
        "Access-Control-Allow-Origin": "*",
        "Content-Security-Policy": "script-src https: 'unsafe-inline' 'unsafe-eval';style-src https: 'unsafe-inline' 'unsafe-eval';img-src https: data:;font-src https: data:;"
      },
    });
    if (token) {
      const decodedToken = jwt(token);
      if (decodedToken.exp * 1000 < new Date().getTime()) {
        localStorage.removeItem("user");
        window.location.href = "/login";
      }
    }
    return res;
  };

  const { isLoading, error, data } = useQuery(["getUnits"], getUnits, {
    select: (balanceData) => {
      let totalConsumption = balanceData?.data?.data?.Total_Consumption;
      let totalSmsCount = Math.round(
        balanceData?.data?.data?.Total_SMS_Count.toFixed(2)
      );

      let result = commaNumber(totalConsumption);
      let newUnit = commaNumber(totalSmsCount);
      return { result, newUnit };
    },
    staleTime: 10 * 60 * 1000,
  });

  if (error) {
    toast.error(error.message);
  }

  return (
    <div className="dashboard">
      <FeaturedInfo />
      <div className="firstWigdet">
        <div className="firstWigdetOne">
          <FirstWidget
            img={Image2}
            title="Total Sms Count"
            data={isLoading ? <Loader /> : data?.newUnit || "No Data"}
          />
        </div>
        <div className="firstWigdetTwo">
          <FirstWidget
            img={Image1}
            title="Total Consumption"
            data={isLoading ? <Loader /> : `₦ ${data?.result}` || "No Data"}
          />
        </div>
      </div>
      <div className="homeWigdet">
        <WidgetLeft />
        <WidgetRight />
      </div>
      <div>
        <table id="my-table">
          <ReportList />
        </table>
      </div>
    </div>
  );
}
