import "./widgetRight.scss";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { Loader } from "../Loader/Loader";
import { BASE_URL } from "../../context/base/base";
import { toast } from "react-toastify";
Chart.register(...registerables);

function checkArray(array) {
  let result = array.every((x) => Number(x) === 0);
  return result;
}

export default function WidgetRight() {
  const userDetail = localStorage.getItem("user");
  const user = JSON.parse(userDetail);

  const token = user?.access_token;

  const api = `${BASE_URL}/auth/bar_chart`;

  const getBarChart = async () => {
    const res = await axios.get(api, {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Frame-Options": "deny",
        "Access-Control-Allow-Origin": "*",
        "Content-Security-Policy": "script-src https: 'unsafe-inline' 'unsafe-eval';style-src https: 'unsafe-inline' 'unsafe-eval';img-src https: data:;font-src https: data:;"
      },
    });
    return res?.data?.data;
  };

  const {
    isLoading,
    error,
    data: barChartData = [],
  } = useQuery(["bardata"], getBarChart, {
    refetchInterval: 60 * 60 * 1000,
    staleTime: 60 * 60 * 1000,
  });

  if (error) {
    toast.error(error.message);
  }

  const hourtime = barChartData?.map((item) => item.FormatedD);
  const valuePerHourTime = barChartData?.map((item) => item.count_total);

  const data = {
    // Name of the variables on x-axies for each bar
    labels: hourtime,
    datasets: [
      {
        // Label for bars
        label: "total",
        barPercentage: 0.4,
        // barThickness: 6,
        maxBarThickness: 10,
        minBarLength: 3,
        // Data or value of your each variable
        data: valuePerHourTime,
        backgroundColor: ["#237FD2"],
      },
    ],
  };
  const options = {
    maintainAspectRatio: false,
    grid: {
      show: false,
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
    },
  };

  return (
    <div className="wigdetRight">
      <div className="barChart">
        <h3 className="barChartTitle">Daily Spend</h3>
        <div className="barChart2">
          <Bar data={data} height={290} options={options} />
        </div>
        {isLoading ? (
          <div className="loader">
            <Loader />
          </div>
        ) : (
          checkArray(barChartData) && <p className="nodata">No data available</p>
        )}
      </div>
    </div>
  );
}
