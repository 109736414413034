import { useState,useContext } from "react";
import "./login.scss";
import logo from "../../assets/svgs/logo.svg";
import smsImg from "../../assets/svgs/two.svg";
import { AuthContext } from "../../context/authContext/AuthContext";
import { login } from "../../context/authContext/apiCalls";
import ClipLoader from "react-spinners/ClipLoader";



export default function Login() {

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { loading , dispatch } = useContext(AuthContext);



  const handleSubmit = async (e) => {
    e.preventDefault();
    login({ username, password }, dispatch)
  };

  return (
    <>
        <div className="login">
          <div className="left">
            <div className="logo">
              <img src={logo} alt="logo" className="logoImg" />
            </div>
            <div className="leftContent">
              <div className="leftContentImg">
                <img src={smsImg} alt="sms" className="smsImg" />
              </div>
              <div className="leftContentTitle">
                Build Scalable Communication Infrastructure
              </div>
              <div className="leftContentDesc">
                Reach your customers with high delivery rate and <br />
                get a detailed report.
              </div>
            </div>
          </div>
          <div className="right">
            <div className="rightContent">
              <div className="rightContentTitle">Welcome Back</div>
              <div className="rightContentTitleSmall">
                Sign into your account
              </div>
              <div className="rightContentForm">
                <form onSubmit={handleSubmit}>
                  <label htmlFor="email">Username</label>
                  <input
                    type="text"
                    // placeholder="Enter your username"
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                  <label htmlFor="password" className="password">Password</label>
                  <input
                    type="password"
                    // placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <button className="loginButton">
                    {loading ? <ClipLoader color="fff" loading={loading} size={40} /> : 'Log In'}
                    </button>
                </form>
              </div>
            </div>
          </div>
        </div>
    </>
  );
}
