// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.featuredInfo {
  font-family: "BRFIRMA";
  margin-left: 2px;
}
.featuredInfo .featuredInfoWrapper {
  padding: 0px 10px;
}
.featuredInfo .featuredInfoWrapper .featuredInfoUserName {
  font-weight: 550;
  padding: 5px;
  margin-left: 3px;
}
.featuredInfo .featuredInfoAmount {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-left: 8px;
}
.featuredInfo .featuredInfoAmount .featuredLeft {
  font-size: 16px;
  font-weight: 550;
}
.featuredInfo .featuredInfoAmount .featuredRight {
  font-size: 16px;
  font-weight: 550;
  margin-left: 8rem;
}
.featuredInfo .featuredInfoAmount .featuredSpan {
  font-size: 14px;
  font-weight: 300;
}
.featuredInfo .loader {
  position: absolute;
  top: 20%;
  left: 26%;
}`, "",{"version":3,"sources":["webpack://./src/components/featuredInfo/featuredInfo.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,gBAAA;AACJ;AAAI;EACI,iBAAA;AAER;AADQ;EACI,gBAAA;EACA,YAAA;EACA,gBAAA;AAGZ;AAAI;EACI,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,gBAAA;AAER;AADQ;EACI,eAAA;EACA,gBAAA;AAGZ;AAAQ;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;AAEZ;AACQ;EACI,eAAA;EACA,gBAAA;AACZ;AAEI;EACI,kBAAA;EACA,QAAA;EACA,SAAA;AAAR","sourcesContent":[".featuredInfo{\n    font-family: \"BRFIRMA\";\n    margin-left: 2px;\n    .featuredInfoWrapper{\n        padding: 0px 10px;\n        .featuredInfoUserName{\n            font-weight: 550;\n            padding: 5px;\n            margin-left: 3px;\n        }\n    }\n    .featuredInfoAmount{\n        display: flex;\n        align-items: center;\n        margin-top: 1rem;\n        margin-left: 8px;\n        .featuredLeft{\n            font-size: 16px;\n            font-weight: 550;\n        }\n\n        .featuredRight{\n            font-size: 16px;\n            font-weight: 550;\n            margin-left: 8rem;\n        }\n\n        .featuredSpan{\n            font-size: 14px;\n            font-weight: 300;\n        }\n    }\n    .loader{\n        position: absolute;\n        top: 20%;\n        left: 26%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
