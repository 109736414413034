import "./sidebar.scss";
import logo from "../../assets/svgs/logo.svg";
import { AssessmentSharp, HomeSharp } from "@material-ui/icons";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

export default function Sidebar() {
  const [active, setActive] = useState(0);
  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case "/dashboard":
        setActive(0);
        break;
      case "/message":
        setActive(1);
        break;
      case "/report":
        setActive(2);
        break;
      default:
        setActive(0);
    }
  }, [location]);

  return (
    <div className="sidebar">
      <div className="sidebarWrapper">
        <div className="sidebarTitle">
          <img src={logo} alt="logo" className="sidebarAvatar" />
        </div>
        <ul className="sidebarList">
          <Link to="/dashboard" className="link">
            <li
              className={active === 0 ? "sidebarListItem active" : "sidebarListItem"}
              onClick={() => setActive(0)}
            >
              <HomeSharp className="sidebarIcon" />
              Overview
            </li>
          </Link>
          <Link to="/message" className="link">
            <li
              className={active === 1 ? "sidebarListItem active" : "sidebarListItem"}
              onClick={() => setActive(1)}
            >
              <AssessmentSharp className="sidebarIcon" />
              Message
            </li>
          </Link>
          <Link to="/report" className="link">
            <li
              className={active === 2 ? "sidebarListItem active" : "sidebarListItem"}
              onClick={() => setActive(2)}
            >
              <AssessmentSharp className="sidebarIcon" />
              Report
            </li>
          </Link>
        </ul>
      </div>
    </div>
  );
}
