export const loginStart = () => ({
    type: "PENDING",
  });
  export const loginSuccess = (user) => ({
    type: "SUCCESS",
    payload: user,
  });
  export const loginFailure = () => ({
    type: "FAILURE",
  });
  
  //logout
  
  export const logoutUser = () => ({
    type: "LOGOUT",
  });