import "./reportList.scss";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { BASE_URL } from "../../context/base/base";
import { toast } from "react-toastify";
import ReportTable from "../ReportTable/ReportTable";

export default function ReportList() {
  const userDetail = localStorage.getItem("user");
  const user = JSON.parse(userDetail);

  const token = user.access_token;

  const api = `${BASE_URL}/auth/recent_report`;

  const getReportList = async () => {
    const res = await axios.get(api, {
      headers: { Authorization: `Bearer ${token}` },
      "X-Frame-Options": "deny",
      "Access-Control-Allow-Origin": "*",
      "Content-Security-Policy": "script-src https: 'unsafe-inline' 'unsafe-eval';style-src https: 'unsafe-inline' 'unsafe-eval';img-src https: data:;font-src https: data:;"
    });
    return res?.data?.data;
  };
  const { isLoading, error, data } = useQuery(
    ["getReportList"],
    getReportList,
    {
      staleTime: 10 * 60 * 1000,
    }
  );

  if (error) {
    toast.error(error.message);
  }

  return (
    <div className="reportList">
      <h3>Recent Reports</h3>
      <ReportTable data={data} loading={isLoading} />
    </div>
  );
}
