import "./topbar.scss";
import logouticon from "../../assets/svgs/logout.svg";
import { useLocation } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../context/authContext/AuthContext";
import { logout } from "../../context/authContext/apiCalls";
import { useNavigate } from "react-router-dom";
import { QueryCache } from '@tanstack/react-query';

export default function Topbar() {
  let location = useLocation();
  let navigate = useNavigate();
  const { dispatch } = useContext(AuthContext);

  const queryCache = new QueryCache();

  const handleLogout = (e) => {
    e.preventDefault();
    queryCache.clear();
    logout(dispatch);
    navigate("./login", { replace: true });
  };

  let pageTitle = "";
  if (location.pathname === "/report") {
    pageTitle = "Report";
  } else if (location.pathname === "/dashboard") {
    pageTitle = "Dashboard";
  } else if (location.pathname === "/message") {
    pageTitle = "Message";
  }

  return (
    <div className="topbar">
      <div className="topbarWrapper">
        <div className="topbarLeft">
           {pageTitle}
        </div>
        <div className="topbarRight" onClick={handleLogout}>
            <img src={logouticon} alt="logout" className="logoutRightIcon" />
            Logout
        </div>
      </div>
    </div>
  );
}