// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reportList {
  font-family: "BRFIRMA";
  height: 60vh;
  padding: 10px 20px;
  flex: 4 1;
  margin-bottom: 5rem;
}
.reportList h3 {
  margin-bottom: 3rem;
}
.reportList .paginate {
  float: right;
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-right: 2.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/report/reportList.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,YAAA;EACA,kBAAA;EAEA,SAAA;EACA,mBAAA;AAAF;AACE;EACE,mBAAA;AACJ;AACE;EACE,YAAA;EACA,gBAAA;EACA,mBAAA;EACA,oBAAA;AACJ","sourcesContent":[".reportList {\n  font-family: \"BRFIRMA\";\n  height: 60vh;\n  padding: 10px 20px;\n  // width: 100%;\n  flex: 4;\n  margin-bottom: 5rem;\n  h3{\n    margin-bottom: 3rem;\n  }\n  .paginate {\n    float: right;\n    margin-top: 2rem;\n    margin-bottom: 2rem;\n    margin-right: 2.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
