import { useContext } from 'react'
import './App.css'
import Login from './pages/login/Login'
import { Route, Routes, useNavigate } from 'react-router-dom'
import Dashboard from './pages/dashboard/Dashboard'
import Report from './pages/report/Report'
import Sidebar from './components/sidebar/Sidebar'
import Topbar from './components/topbar/Topbar'
import { Outlet } from 'react-router-dom'
import { AuthContext } from './context/authContext/AuthContext'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ProtectedRoute from './components/protectedRoute/ProtectedRoute'
import  Message from './pages/messages/Message';

const Layout = () => (
  <div className="container">
    <Sidebar />
    <div className="others">
      <Topbar />
      <Outlet />
    </div>
  </div>
)

function App() {
  const { user } = useContext(AuthContext)

  // const navigate = useNavigate();

  return (
    <>
      <Routes>
        <Route element={<Layout />}>
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute isAllowed={!!user}>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/report"
            element={
              <ProtectedRoute isAllowed={!!user}>
                <Report />
              </ProtectedRoute>
            }
          />
          <Route
            path="/message"
            element={
              <ProtectedRoute isAllowed={!!user}>
                <Message />
              </ProtectedRoute>
            }
          />
        </Route>)
        <Route
          path="/"
          element={
            <ProtectedRoute isAllowed={!!user}>
              <Dashboard />
            </ProtectedRoute>
          }
        />
          <Route path="/login" element={<Login />} />
      </Routes>
      <ToastContainer />
    </>
  )
}

export default App;
