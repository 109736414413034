import "./featuredInfo.scss";
import axios from "axios";
import jwt from "jwt-decode";
import commaNumber from "comma-number";

import { Loader } from "../Loader/Loader";
import { useQuery } from "@tanstack/react-query";
import { BASE_URL } from "../../context/base/base";
import { toast } from "react-toastify";

export default function FeaturedInfo() {
  const userDetail = localStorage.getItem("user");
  const user = JSON.parse(userDetail);

  const token = user?.access_token;
  const username = user?.username;

  const api = `${BASE_URL}/auth/balance`;

  const getBalance = async () => {
    const res = await axios.get(api, {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Frame-Options": "deny",
        "Access-Control-Allow-Origin": "*",
        "Content-Security-Policy": "script-src https: 'unsafe-inline' 'unsafe-eval';style-src https: 'unsafe-inline' 'unsafe-eval';img-src https: data:;font-src https: data:;"
      },
    });
    if (token) {
      const decodedToken = jwt(token);
      if (decodedToken.exp * 1000 < new Date().getTime()) {
        localStorage.removeItem("user");
        window.location.href = "/login";
      }
    }
    return res;
  };
  const { isLoading, error, data } = useQuery(["getBalance"], getBalance, {
    select: (balanceData) => {
      let balanceCredit = balanceData?.data?.data?.data?.balance?.toFixed(2);
      let balanceUnit = Math.round(balanceData?.data?.data?.balance_unit);

      let result = commaNumber(balanceCredit);
      let newUnit = commaNumber(balanceUnit);

      return { result, newUnit };
    },
    staleTime: 10 * 60 * 1000,
  });
  const unitbalanceUsers = ["VNSMTNT", "VNSARTLT", "VNSMTNP", "VNSARTLP"];

  if (error) {
    toast.error(error.message);
  }

  return (
    <div className="featuredInfo">
      <div className="featuredInfoWrapper">
        <div className="featuredInfoUser">
          <h3 className="featuredInfoUserName">Hi {user?.username} 👋🏿</h3>
        </div>
        <div className="featuredInfoAmount">
          <div className="featuredLeft">
            <h3>
              ₦{" "}
              {isLoading ? (
                <div className="loader">
                  <Loader />
                </div>
              ) : (
                data?.result || "No Data"
              )}{" "}
              <br /> <span className="featuredSpan">Balance Credits</span>
            </h3>
          </div>
          {unitbalanceUsers?.includes(username) && (
            <div className="featuredRight">
              <h3>
                {isLoading ? <Loader /> : data?.newUnit || "No Data"}
                <br /> <span className="featuredSpan">Balance Units</span>
              </h3>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
