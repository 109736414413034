// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wigdetRight {
  flex: 1 1;
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 15px -10pxrgba 0, 0, 0, 0.75;
  -moz-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  padding: 15px;
  font-family: "BRFIRMA";
}
.wigdetRight .barChart .barChartTitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: -2rem;
}
.wigdetRight .barChart .barChart2 {
  width: 100%;
  margin-top: 3rem;
}
.wigdetRight .loader {
  position: absolute;
  top: 60%;
  left: 80%;
}`, "",{"version":3,"sources":["webpack://./src/components/wigdetRightChat/widgetRight.scss"],"names":[],"mappings":"AAAA;EACI,SAAA;EACA,kDAAA;EACA,wDAAA;EACA,uDAAA;EACA,aAAA;EACA,sBAAA;AACJ;AAEQ;EACI,eAAA;EACA,iBAAA;EACA,oBAAA;AAAZ;AAEQ;EACI,WAAA;EACA,gBAAA;AAAZ;AAGI;EACI,kBAAA;EACA,QAAA;EACA,SAAA;AADR","sourcesContent":[".wigdetRight{\n    flex: 1;\n    box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);\n    -webkit-box-shadow: 0px 0px 15px -10pxrgba(0,0,0,0.75);\n    -moz-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);\n    padding: 15px;\n    font-family: \"BRFIRMA\";   \n    .barChart{\n        // padding: 5px;\n        .barChartTitle{\n            font-size: 18px;\n            font-weight: bold;\n            margin-bottom: -2rem;\n        }\n        .barChart2{\n            width: 100%;\n            margin-top: 3rem;\n        }\n    }\n    .loader{\n        position: absolute;\n        top: 60%;\n        left: 80%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
