const AuthReducer = (state, action) => {
    switch (action.type) {
      case "PENDING":
        return {
          user: null,
          loading: true,
          error: false,
        };
      case "SUCCESS":
        return {
          user: action.payload,
          loading: false,
          error: false,
        };
      case "FAILURE":
        return {
          user: null,
          loading: false,
          error: true,
        };
      case "LOGOUT":
        return {
          user: null,
          loading: false,
          error: false,
        };
      default:
        return { ...state };
    }
  };
  
  export default AuthReducer;