import axios from 'axios'
import {
  loginFailure,
  loginStart,
  loginSuccess,
  logoutUser,
} from './AuthActions'
import { toast } from 'react-toastify'
import { BASE_URL } from '../base/base'

export const login = async (user, dispatch) => {
  dispatch(loginStart())
  try {
    const res = await axios.post(`${BASE_URL}/auth/login`, user)
    dispatch(loginSuccess(res.data.data))
    toast.success('Logged In Successfully! 🦄', {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      progress: undefined,
    })
    window.location = '/dashboard'
  } catch (err) {
    dispatch(loginFailure())
    toast.error(err.response.data.message, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      progress: undefined,
    })
  }
}

export const logout = async (dispatch) => {
  dispatch(logoutUser())
  localStorage.removeItem('user')
  toast.error('Logged Out Successfully! 🦄', {
    position: 'top-right',
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    progress: undefined,
  })
}
