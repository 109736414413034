import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Box,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import "./reactTable.scss";
import { ColorRing } from "react-loader-spinner";
import commaNumber from "comma-number";

const useStyles = makeStyles({
  tableRow: {
    height: "auto"
  }
});
function Row(props) {
  const { row, isMonthSelected,yearValue } = props;
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  const formatTime = (output) => {
    const date = new Date(output);
    const options = {
      day: "numeric",
      month: "short",
      year: "numeric",
    };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );
    return formattedDate;
  };

  const monArr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  
  const formatTimeMon = (output) => {
    return monArr[+output-1];
  };

  return (
    <React.Fragment>
      <TableRow hover onClick={() => setOpen(!open)} className={classes.tableRow}>
        <TableCell style={{ width: "5%" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          style={{ fontWeight: "bold", fontFamily: "BRFIRMA",width:"25%" }}
          component="th"
          scope="row"
        >
          {isMonthSelected ? `${formatTimeMon(row.date)},${yearValue}` : formatTime(row.date)}
        </TableCell>
        <TableCell style={{ fontFamily: "BRFIRMA", fontWeight: "light",width:"35%" }}>
          {commaNumber(row.total_count)}
        </TableCell>
        <TableCell style={{ fontFamily: "BRFIRMA", fontWeight: "bold",width:"35%" }}>
          ₦ {commaNumber(row.total_cost)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
          }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table>
                <TableBody>
                  {(row.mtn_cost || row.mtn_count) && (
                    <TableRow>
                      <TableCell
                        style={{
                          fontFamily: "BRFIRMA",
                          fontWeight: "bold",
                          fontSize: "13px",
                          width: "20%",
                          paddingLeft: "3.9rem",
                        }}
                        align=""
                        component="th"
                        scope="row"
                      >
                        MTN
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: "BRFIRMA",
                          width: "40%",
                          paddingLeft:"7.5rem",
                        }}
                      >
                        {row.mtn_count ? commaNumber(row.mtn_count) : ""}
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: "BRFIRMA",
                          width: "40%",
                          paddingLeft: "5rem",
                        }}
                      >
                       {row.mtn_cost ? `₦ ${commaNumber(row.mtn_cost)}` : ""}
                      </TableCell>
                    </TableRow>
                  )}
                  {(row.glo_cost || row.glo_count) && (
                    <TableRow>
                      <TableCell
                        style={{
                          fontFamily: "BRFIRMA",
                          fontWeight: "bold",
                          fontSize: "13px",
                          paddingLeft: "3.9rem",
                          width: "20%",
                        }}
                        component="th"
                        scope="row"
                      >
                        GLO
                      </TableCell>
                      <TableCell style={{ fontFamily: "BRFIRMA", width: "40%",
                          paddingLeft: "7.5rem" }}>
                        {row.glo_count ? commaNumber(row.glo_count) : ""}
                      </TableCell>
                      <TableCell style={{ fontFamily: "BRFIRMA", width: "40%",
                          paddingLeft:"5rem" }}>
                        {row.glo_cost ? `₦ ${commaNumber(row.glo_cost)}` : ""}
                      </TableCell>
                    </TableRow>
                  )}
                  {(row.airtel_cost || row.airtel_count) && (
                    <TableRow>
                      <TableCell
                        style={{
                          fontFamily: "BRFIRMA",
                          fontWeight: "bold",
                          fontSize: "13px",
                          paddingLeft: "3.9rem",
                          width: "20%"
                        }}
                        component="th"
                        scope="row"
                      >
                        AIRTEL
                      </TableCell>
                      <TableCell style={{ fontFamily: "BRFIRMA", width: "40%",
                          paddingLeft:"7.5rem" }}>
                        {row.airtel_count ? commaNumber(row.airtel_count) : ""}
                      </TableCell>
                      <TableCell style={{ fontFamily: "BRFIRMA", width: "40%",
                          paddingLeft:"5rem" }}>
                        {row.airtel_cost ? `₦ ${commaNumber(row.airtel_cost)}` : ""}
                      </TableCell>
                    </TableRow>
                  )}
                  {(row.gmobile_cost || row.gmobile_count) && (
                    <TableRow>
                      <TableCell
                        style={{
                          fontFamily: "BRFIRMA",
                          fontWeight: "bold",
                          fontSize: "13px",
                          paddingLeft: "3.9rem",
                          width: "20%"
                        }}
                        component="th"
                        scope="row"
                      >
                        9MOBILE
                      </TableCell>
                      <TableCell style={{ fontFamily: "BRFIRMA", width: "40%",
                          paddingLeft:"7.5rem" }}>
                        {row.gmobile_count ? commaNumber(row.gmobile_count) : ""}
                      </TableCell>
                      <TableCell style={{ fontFamily: "BRFIRMA", width: "40%",
                          paddingLeft:"5rem" }}>
                        {row.gmobile_cost ? `₦ ${commaNumber(row.gmobile_cost)}` : ""}
                      </TableCell>
                    </TableRow>
                  )}
                  {(row.global_cost || row.global_count) && (
                    <TableRow>
                      <TableCell
                        style={{
                          fontFamily: "BRFIRMA",
                          fontWeight: "bold",
                          fontSize: "13px",
                          width: "20%",
                          paddingLeft: "3.9rem",
                        }}
                        align=""
                        component="th"
                        scope="row"
                      >
                        GLOBAL
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: "BRFIRMA",
                          width: "40%",
                          paddingLeft:"7.5rem",
                        }}
                      >
                        {row.mtn_count ? commaNumber(row.global_count) : ""}
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: "BRFIRMA",
                          width: "40%",
                          paddingLeft: "5rem",
                        }}
                      >
                       {row.mtn_cost ? `₦ ${commaNumber(row.global_cost)}` : ""}
                      </TableCell>
                    </TableRow>
                  )}
                
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function ReportTable(props) {
  const { data, loading, isMonthSelected,yearValue } = props;
  const classes = useStyles();

  return (
    <div className="tableContainer">
      <Paper sx={{ width: "80%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 500 }}>
          <Table className={classes.table} aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell
                  style={{ fontWeight: "bold", fontFamily: "BRFIRMA" }}
                >
                  Date
                </TableCell>
                <TableCell
                  style={{ fontWeight: "bold", fontFamily: "BRFIRMA" }}
                >
                  Total Sms Sent
                </TableCell>
                <TableCell
                  style={{ fontWeight: "bold", fontFamily: "BRFIRMA" }}
                >
                  Amount
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && (!data || data.message === "No report found") ? (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No Result
                  </TableCell>
                </TableRow>
              ) : (
                data?.map((row, index) => (
                  <Row
                    key={index}
                    row={row}
                    isMonthSelected={isMonthSelected}
                    yearValue={yearValue}
                  />
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {loading && (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              marginTop: "1.5rem",
            }}
          >
            <ColorRing
              height="50"
              width="50"
              color="#4094f7"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["#4285f4", "#64b5f6", "#90caf9", "#b0bec5", "#78909c"]}
            />
          </Box>
        )}
      </Paper>
    </div>
  );
}

export default ReportTable;
