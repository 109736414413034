// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.widgetDiv {
  flex: 1 1;
  box-shadow: 3px 3px 3px 3px rgba(214, 210, 210, 0.75);
  padding: 25px;
  border-radius: 6px;
  font-family: "BRFIRMA";
  height: 15vh;
  width: 100%;
  display: flex;
  margin-bottom: 2rem;
}
.widgetDiv img {
  width: 50px;
  height: 45px;
  margin-right: 1.5rem;
  margin-top: 0.7rem;
}
.widgetDiv h3 {
  font-size: 1rem;
  margin-top: 0.5rem;
  font-weight: 400;
  margin-bottom: 1.2rem;
}
.widgetDiv p {
  font-size: 20px;
  font-weight: bold;
  margin-top: -1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/FirstWidget/firstWidget.scss"],"names":[],"mappings":"AAAA;EACI,SAAA;EACA,qDAAA;EACA,aAAA;EACA,kBAAA;EACA,sBAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;AACJ;AACI;EACI,WAAA;EACA,YAAA;EACA,oBAAA;EACA,kBAAA;AACR;AACI;EACI,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,qBAAA;AACR;AACI;EACE,eAAA;EACA,iBAAA;EACA,iBAAA;AACN","sourcesContent":[".widgetDiv{\n    flex: 1;\n    box-shadow: 3px 3px 3px 3px rgba(214, 210, 210, 0.75);\n    padding: 25px;\n    border-radius: 6px;\n    font-family: \"BRFIRMA\";\n    height: 15vh;\n    width: 100%;\n    display: flex;\n    margin-bottom: 2rem;\n\n    img{\n        width: 50px;\n        height: 45px;\n        margin-right: 1.5rem;\n        margin-top: 0.7rem;\n    }\n    h3{\n        font-size: 1rem;\n        margin-top: 0.5rem;\n        font-weight: 400;\n        margin-bottom: 1.2rem;\n    }\n    p{\n      font-size: 20px;\n      font-weight: bold;\n      margin-top: -1rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
