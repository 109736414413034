import "./wigdetLeft.scss";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import axios from "axios";
import { Loader } from "../Loader/Loader";
import { useQuery } from "@tanstack/react-query";
import { BASE_URL } from "../../context/base/base";
import { toast } from "react-toastify";

ChartJS.register(ArcElement, Tooltip, Legend);

function checkArray(array) {
  let result = array.every((x) => Number(x) === 0);
  return result;
}

export default function WidgetLeft() {
  const userDetail = localStorage.getItem("user");
  const user = JSON.parse(userDetail);

  const token = user?.access_token;

  const api = `${BASE_URL}/auth/pie_chart`;

  const getPieChart = async () => {
    const res = await axios.get(api, {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Frame-Options": "deny",
        "Access-Control-Allow-Origin": "*",
        "Content-Security-Policy": "script-src https: 'unsafe-inline' 'unsafe-eval';style-src https: 'unsafe-inline' 'unsafe-eval';img-src https: data:;font-src https: data:;"
      },
    });
    return res;
  };
  const {
    isLoading,
    error,
    data: reportData = [],
  } = useQuery(["piedata"], getPieChart, {
    select: (recentData) => {
      let newReport = recentData?.data?.pie_data?.data;

      let result = [];
      for (const key in newReport) {
        if (newReport?.hasOwnProperty.call(newReport, key)) {
          const elem = newReport[key];
          result.push(elem);
        }
      }
      let finalData = result.slice(0, 5);
      return finalData;
    },
    refetchInterval: 10 * 60 * 1000,
    staleTime: 10 * 60 * 1000,
  });

  const total = reportData.reduce((acc, cur) => acc + cur, 0);

  const inPercent = reportData.map((v) => {
    if (v === 0) {
      return 0;
    }
    return (v / total) * 100;
  });

  if (error) {
    toast.error(error.message);
  }

  const data = {
    labels: ["Airtel", "Glo", "Global", "9Mobile", "Mtn"],
    datasets: [
      {
        label: "PieChart",
        backgroundColor: [
          "#EE1C25",
          "#006E52",
          "#3458EB",
          "#13A607",
          "#FFC423CC",
        ],
        data: inPercent,
        tooltip: {
          callbacks: {
            label: function (context) {
              let label = context.label;
              let value = context.formattedValue;

              if (!label) label = "Unknown";

              let sum = 0;
              let dataArr = context.chart.data.datasets[0].data;
              dataArr.map((data) => {
                sum += Number(data);
              });

              let percentage = ((value * 100) / sum).toFixed(2) + "%";
              return label + ": " + percentage;
            },
          },
        },
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    title: {
      display: true,
      text: "Sms Sent",
      fontSize: 20,
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
        },
      },
    },
  };

  return (
    <div className="wigdetLeft">
      <h3>Sms Sent</h3>
      <div className="pieChart">
        <Pie data={data} options={options} />
      </div>
      {isLoading ? (
        <div className="loader">
          <Loader />
        </div>
      ) : (
        checkArray(reportData) && <p className="nodata">No data available</p>
      )}
    </div>
  );
}
