// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

body,
#app {
	box-sizing: border-box;
	font-family: Roboto, sans-serif !important;
	font-weight: 400 !important;
}

html,
body,
#app,
#app > div {
	height: 100%;
	width: 100%;
}
:root{
  --main-color : #F5F5F5;
}
.container{
  display: flex;
}
.others{
  flex: 5 1;
}

.link{
  text-decoration: none;
  color: inherit;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;;CAGC,SAAS;CACT,UAAU;CACV,mBAAmB;AACpB;;AAEA;;CAEC,sBAAsB;CACtB,0CAA0C;CAC1C,2BAA2B;AAC5B;;AAEA;;;;CAIC,YAAY;CACZ,WAAW;AACZ;AACA;EACE,sBAAsB;AACxB;AACA;EACE,aAAa;AACf;AACA;EACE,SAAO;AACT;;AAEA;EACE,qBAAqB;EACrB,cAAc;AAChB","sourcesContent":["*,\n*::after,\n*::before {\n\tmargin: 0;\n\tpadding: 0;\n\tbox-sizing: inherit;\n}\n\nbody,\n#app {\n\tbox-sizing: border-box;\n\tfont-family: Roboto, sans-serif !important;\n\tfont-weight: 400 !important;\n}\n\nhtml,\nbody,\n#app,\n#app > div {\n\theight: 100%;\n\twidth: 100%;\n}\n:root{\n  --main-color : #F5F5F5;\n}\n.container{\n  display: flex;\n}\n.others{\n  flex: 5;\n}\n\n.link{\n  text-decoration: none;\n  color: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
