// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard {
  margin-top: 2rem;
  width: 100%;
}
.dashboard .firstWigdet {
  display: flex;
  margin-left: 5px;
  margin-top: 1rem;
}
.dashboard .firstWigdet .firstWigdetOne {
  margin-left: 1rem;
  margin-right: 3rem;
}
.dashboard .firstWigdet .firstWigdetTwo {
  margin-left: 2rem;
}
.dashboard .homeWigdet {
  width: 95%;
  display: flex;
  margin: 20px;
  height: 48vh;
}
.dashboard #my-table {
  width: 98%;
  margin-top: 1.5rem;
  margin-left: 0.1rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/dashboard.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,WAAA;AACF;AAAE;EACE,aAAA;EAEA,gBAAA;EACA,gBAAA;AACJ;AAAI;EACE,iBAAA;EACA,kBAAA;AAEN;AACI;EACE,iBAAA;AACN;AAEI;EACE,UAAA;EACA,aAAA;EACA,YAAA;EACA,YAAA;AAAN;AAEI;EACE,UAAA;EACA,kBAAA;EACA,mBAAA;AAAN","sourcesContent":[".dashboard{\n  margin-top: 2rem;\n  width: 100%;\n  .firstWigdet{\n    display: flex;\n\n    margin-left: 5px;\n    margin-top: 1rem;\n    .firstWigdetOne{\n      margin-left: 1rem;\n      margin-right: 3rem;\n      \n    }\n    .firstWigdetTwo{\n      margin-left: 2rem;\n    }\n  }\n    .homeWigdet{\n      width: 95%;\n      display: flex;\n      margin: 20px;\n      height: 48vh;\n    }\n    #my-table {\n      width: 98%;\n      margin-top: 1.5rem;\n      margin-left: 0.1rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
