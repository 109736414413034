import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Tooltip } from "antd";
import { ExclamationCircleFilled  } from "@ant-design/icons";
import "./messagetable.scss";
import { Box } from "@mui/material";
import { ColorRing } from "react-loader-spinner";

export default function MessageTable({ data, column, loading }) {
  const MessageTooltip = ({ message }) => {
    const [showTooltip, setShowTooltip] = React.useState(false);
    const iconRef = React.useRef();

    return (
      <div style={{ display: "flex" }}>
        <Tooltip title={message} visible={showTooltip} placement="right" fill="#000"
>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: "200px",
              color: "#000",
            }}
          >
            {message}
          </div>
        </Tooltip>
        <div
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          ref={iconRef}
        >
          <ExclamationCircleFilled
            style={{
              verticalAlign: "middle",
              cursor: "pointer",
              marginLeft: "0.3rem",
              fontSize: "20px",
              marginTop: "0.1rem",
            }}
          />
        </div>
      </div>
    );
  };
  
  const formatTime = (output) => {
    const date = new Date(output);
    const options = {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false,
    };
    const formattedDate = new Intl.DateTimeFormat("en-US", options)
      .format(date)
      .replace(",", "");
    return formattedDate;
  };

  return (
    <div className="tableContainer">
      <Paper sx={{ width: "96%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {column?.map((column) => (
                  <TableCell
                    key={column.id}
                    sx={{
                      fontSize: "15px",
                      fontFamily: "BRFIRMA",
                      fontWeight: "bold",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && data?.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No Result
                  </TableCell>
                </TableRow>
              ) : (
                data?.map((data) => (
                  <TableRow
                    key={data?.SenderID}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        fontSize: "13px",
                        fontFamily: "BRFIRMA",
                        fontWeight: 300,
                      }}
                    >
                      <MessageTooltip message={data.Message} />
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "13px",
                        fontFamily: "BRFIRMA",
                        fontWeight: 300,
                      }}
                    >
                      {data?.Recipent}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "13px",
                        fontFamily: "BRFIRMA",
                        fontWeight: 300,
                      }}
                    >
                      {data?.Network?.split("_")[0]}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "13px",
                        fontFamily: "BRFIRMA",
                        fontWeight: 300,
                      }}
                    >
                      {data?.SenderID}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "13px",
                        fontFamily: "BRFIRMA",
                        fontWeight: 300,
                      }}
                    >
                      {data?.Unit !== null ? data?.Unit : "null"} <br />{" "}
                      {data?.SentTime && !data?.DeliveryTime
                        ? ""
                        : data?.Errorcode !== null
                        ? data?.errorcode
                        : "null"}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "13px",
                        fontFamily: "BRFIRMA",
                        fontWeight: "light",
                      }}
                      
                    >
                      {data?.SentTime !== null
                        ? formatTime(data?.SentTime)
                        : "-"}
                      <br />{" "}
                      {data?.DeliveryTime != null
                        ? formatTime(data?.DeliveryTime)
                        : "-"}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "11px",
                        fontFamily: "BRFIRMA",
                        fontWeight: 300,
                      }}
                    >
                      <span className={data?.Status ? data.Status : "pending"}>
                        {data?.Status === "DELIVRD" && "Delivered"}
                        {data?.Status === "UNDELIV" && "Undelivered"}
                        {data?.Status === "EXPIRED" && "Expired"}
                        {data?.Status === "REJECTD" && "Rejected"}
                        {data?.Status === "UNKNOWN" && "Unknown"}
                        {data?.Status === null && "Pending"}
                      </span>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              marginTop: "2rem",
            }}
          >
            <ColorRing
              height="50"
              width="50"
              color="#4094f7"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["#4285f4", "#64b5f6", "#90caf9", "#b0bec5", "#78909c"]}
            />
          </Box>
        )}
      </Paper>
    </div>
  );
}
