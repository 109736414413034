// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableContainer .DELIVRD {
  background-color: #ECFDF3;
  color: #008138;
  padding: 2px 8px;
  width: 64px;
  height: 24px;
  border-radius: 5px;
}
.tableContainer .FAILED {
  background-color: #fdf2f2;
  color: #ff0000;
  padding: 3px;
  border-radius: 5px;
}
.tableContainer .pending {
  background-color: #fdf2f2;
  color: #cd9a2d;
  padding: 3px;
  border-radius: 5px;
}
.tableContainer .REJECTD {
  background-color: #fdf2f2;
  color: #ff0000;
  padding: 3px;
  border-radius: 5px;
}
.tableContainer .UNDELIV {
  background-color: #fdf2f2;
  color: #ff0000;
  padding: 3px;
  border-radius: 5px;
}
.tableContainer .UNKNOWN {
  background-color: #fdf2f2;
  color: #ff0000;
  padding: 3px;
  border-radius: 5px;
}
.tableContainer .EXPIRED {
  background-color: #fdf2f2;
  color: #ff0000;
  padding: 3px;
  border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/MessageTable/messagetable.scss"],"names":[],"mappings":"AACE;EACE,yBAAA;EACA,cAAA;EACA,gBAAA;EAEA,WAAA;EACA,YAAA;EACA,kBAAA;AADJ;AAGE;EACE,yBAAA;EACA,cAAA;EACA,YAAA;EACA,kBAAA;AADJ;AAGE;EACE,yBAAA;EACA,cAAA;EACA,YAAA;EACA,kBAAA;AADJ;AAGE;EACE,yBAAA;EACA,cAAA;EACA,YAAA;EACA,kBAAA;AADJ;AAGE;EACE,yBAAA;EACA,cAAA;EACA,YAAA;EACA,kBAAA;AADJ;AAGE;EACE,yBAAA;EACA,cAAA;EACA,YAAA;EACA,kBAAA;AADJ;AAGE;EACE,yBAAA;EACA,cAAA;EACA,YAAA;EACA,kBAAA;AADJ","sourcesContent":[".tableContainer {\n  .DELIVRD {\n    background-color: #ECFDF3;\n    color: #008138;\n    padding: 2px 8px;\n\n    width: 64px;\n    height: 24px;\n    border-radius: 5px;\n  }\n  .FAILED {\n    background-color: #fdf2f2;\n    color: #ff0000;\n    padding: 3px;\n    border-radius: 5px;\n  }\n  .pending{\n    background-color: #fdf2f2;\n    color: #cd9a2d;\n    padding: 3px;\n    border-radius: 5px;\n  }\n  .REJECTD {\n    background-color: #fdf2f2;\n    color: #ff0000;\n    padding: 3px;\n    border-radius: 5px;\n  }\n  .UNDELIV {\n    background-color: #fdf2f2;\n    color: #ff0000;\n    padding: 3px;\n    border-radius: 5px;\n  }\n  .UNKNOWN {\n    background-color: #fdf2f2;\n    color: #ff0000;\n    padding: 3px;\n    border-radius: 5px;\n  }\n  .EXPIRED {\n    background-color: #fdf2f2;\n    color: #ff0000;\n    padding: 3px;\n    border-radius: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
