// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wigdetLeft {
  flex: 1 1;
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 15px -10pxrgba 0, 0, 0, 0.75;
  -moz-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  padding: 20px;
  margin-right: 20px;
  font-family: "BRFIRMA";
  position: relative;
}
.wigdetLeft .pieChart {
  height: 35vh;
  margin-bottom: 3rem;
}
.wigdetLeft .nodata {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.wigdetLeft .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}`, "",{"version":3,"sources":["webpack://./src/components/widgetlLeftChat/wigdetLeft.scss"],"names":[],"mappings":"AAAA;EACI,SAAA;EACA,kDAAA;EACA,wDAAA;EACA,uDAAA;EACA,aAAA;EACA,kBAAA;EACA,sBAAA;EACA,kBAAA;AACJ;AAAI;EACI,YAAA;EACA,mBAAA;AAER;AAAI;EACI,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;AAER;AAAI;EACI,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;AAER","sourcesContent":[".wigdetLeft{\n    flex: 1;\n    box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);\n    -webkit-box-shadow: 0px 0px 15px -10pxrgba(0,0,0,0.75);\n    -moz-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);\n    padding: 20px;\n    margin-right: 20px;\n    font-family: \"BRFIRMA\";\n    position: relative;\n    .pieChart{\n        height: 35vh;\n        margin-bottom: 3rem;\n    }\n    .nodata{\n        position: absolute;\n        top: 50%;\n        left: 50%;\n        transform:translate(-50%, -50%);\n    }\n    .loader{\n        position: absolute;\n        top: 50%;\n        left: 50%;\n        transform:translate(-50%, -50%);\n      }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
