// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
  flex: 1 1;
  background-color: #F2F4F8;
  height: 100vh;
  position: sticky;
  top: 0px;
}
.sidebar .sidebarWrapper {
  padding: 10px;
  font-family: "BRFIRMA";
  margin-left: 1rem;
}
.sidebar .sidebarWrapper .sidebarTitle img {
  width: 100px;
  height: 70px;
  margin-left: 1rem;
  cursor: pointer;
}
.sidebar .sidebarWrapper .sidebarList {
  list-style: none;
  padding: 5px;
  margin-left: 5px;
  margin-top: 2rem;
  color: #252c32;
}
.sidebar .sidebarWrapper .sidebarList .sidebarListItem {
  font-size: 14px;
  font-weight: 300;
  padding: 8px;
  margin-bottom: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
}
.sidebar .sidebarWrapper .sidebarList .sidebarListItem .sidebarIcon {
  font-size: 20px;
  margin-right: 1rem;
}
.sidebar .sidebarWrapper .sidebarList .sidebarListItem.active, .sidebar .sidebarWrapper .sidebarList .sidebarListItem:hover {
  background-color: #4094f7;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}
.sidebar .sidebarWrapper .sidebarList .sidebarListItem.active .sidebarIcon, .sidebar .sidebarWrapper .sidebarList .sidebarListItem:hover .sidebarIcon {
  color: #fff;
}
.sidebar .active {
  background-color: #4094f7;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}
.sidebar .active .sidebarIcon {
  color: #4094f7;
}`, "",{"version":3,"sources":["webpack://./src/components/sidebar/sidebar.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,yBAAA;EACA,aAAA;EACA,gBAAA;EACA,QAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;AACJ;AAEM;EACE,YAAA;EACA,YAAA;EACA,iBAAA;EACA,eAAA;AAAR;AAII;EACE,gBAAA;EACA,YAAA;EACA,gBAAA;EACA,gBAAA;EACA,cAAA;AAFN;AAIM;EACE,eAAA;EACA,gBAAA;EACA,YAAA;EACA,mBAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;AAFR;AAIQ;EACE,eAAA;EACA,kBAAA;AAFV;AAKQ;EAEE,yBAAA;EACA,WAAA;EACA,eAAA;EACA,gBAAA;AAJV;AAMU;EACE,WAAA;AAJZ;AAWE;EACE,yBAAA;EACA,WAAA;EACA,eAAA;EACA,gBAAA;AATJ;AAWI;EACE,cAAA;AATN","sourcesContent":[".sidebar {\n  flex: 1;\n  background-color: #F2F4F8;\n  height: 100vh;\n  position: sticky;\n  top: 0px;\n\n  .sidebarWrapper {\n    padding: 10px;\n    font-family: \"BRFIRMA\";\n    margin-left: 1rem;\n    \n    .sidebarTitle {\n      img {\n        width: 100px;\n        height: 70px;\n        margin-left: 1rem;\n        cursor: pointer;\n      }\n    }\n    \n    .sidebarList {\n      list-style: none;\n      padding: 5px;\n      margin-left: 5px;\n      margin-top: 2rem;\n      color: #252c32;\n      \n      .sidebarListItem {\n        font-size: 14px;\n        font-weight: 300;\n        padding: 8px;\n        margin-bottom: 1rem;\n        cursor: pointer;\n        display: flex;\n        align-items: center;\n        border-radius: 10px;\n        \n        .sidebarIcon {\n          font-size: 20px;\n          margin-right: 1rem;\n        }\n        \n        &.active,\n        &:hover {\n          background-color: #4094f7;\n          color: #fff;\n          font-size: 16px;\n          font-weight: 600;\n          \n          .sidebarIcon {\n            color: #fff;\n          }  \n        }\n      }\n    }\n  }\n  \n  .active {\n    background-color: #4094f7;\n    color: #fff;\n    font-size: 18px;\n    font-weight: 600;\n    \n    .sidebarIcon {\n      color: #4094f7;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
