// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topbar {
  width: 100%;
  height: 80px;
  border-bottom: 1px solid #eee;
  font-family: "BRFIRMA";
}
.topbar .topbarWrapper {
  height: 100%;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.topbar .topbarWrapper .topbarLeft {
  font-weight: bold;
  margin-left: 1px;
}
.topbar .topbarWrapper .topbarRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 2rem;
  font-size: 15px;
  font-weight: 200;
  cursor: pointer;
}
.topbar .topbarWrapper .topbarRight .logoutRightIcon {
  font-size: 20px;
  margin-right: 5px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/topbar/topbar.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,6BAAA;EACA,sBAAA;AACF;AAAE;EACE,YAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AAEJ;AADI;EACI,iBAAA;EACA,gBAAA;AAGR;AADI;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,eAAA;AAGN;AAFM;EACE,eAAA;EACA,iBAAA;EACA,eAAA;AAIR","sourcesContent":[".topbar {\n  width: 100%;\n  height: 80px;\n  border-bottom: 1px solid #eee;\n  font-family: \"BRFIRMA\";\n  .topbarWrapper {\n    height: 100%;\n    padding: 0px 20px;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    .topbarLeft{\n        font-weight: bold;\n        margin-left: 1px;\n    }\n    .topbarRight {\n      display: flex;\n      align-items: center;\n      justify-content: flex-end;\n      margin-right: 2rem;\n      font-size: 15px;\n      font-weight: 200;\n      cursor: pointer;\n      .logoutRightIcon{\n        font-size: 20px;\n        margin-right: 5px;\n        cursor: pointer;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
