import "./firstWidget.scss";


export default function FirstWidget({title,data,img}) {

  return (
    <div className="widgetDiv">
      <div>
        <img src={img}  alt="images" className="imgClass"/>
      </div>
      <div>
      <h3>{title}</h3>
      <p className="widgetContent">
        {data}
        </p>
      </div>
    </div>
  );
}
