// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button {
  font-family: "BRFIRMA";
  height: 40px;
  padding: 5px;
  font-size: 13px;
  font-weight: bold;
  color: #344054;
  background: #ffffff;
  border: 1px solid #e4e7ec;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
}
button.outline {
  color: #fff;
  background-color: transparent;
  border: 1px solid #fff;
}
button.outline:hover {
  background-color: #fff;
  color: #000;
}
button.danger {
  background-color: #dc3545;
}
button.danger:hover {
  background-color: #bd2130;
}
button.small {
  padding: 6px 12px;
  font-size: 14px;
}
button.large {
  padding: 12px 24px;
  font-size: 18px;
}`, "",{"version":3,"sources":["webpack://./src/components/Button/button.scss"],"names":[],"mappings":"AAEA;EACE,sBAAA;EAEA,YAAA;EACA,YAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,mBAAA;EACA,yBAAA;EACA,kBAAA;EACA,eAAA;EACA,yBAAA;AAFF;AAKE;EACE,WAlBY;EAmBZ,6BAAA;EACA,sBAAA;AAHJ;AAKI;EACE,sBAvBU;EAwBV,WAAA;AAHN;AAOE;EACE,yBAAA;AALJ;AAOI;EACE,yBAAA;AALN;AASE;EACE,iBAAA;EACA,eAAA;AAPJ;AAUE;EACE,kBAAA;EACA,eAAA;AARJ","sourcesContent":["$primary-color: #fff;\n\nbutton {\n  font-family: \"BRFIRMA\";\n  // width: 150%;\n  height: 40px;\n  padding: 5px;\n  font-size: 13px;\n  font-weight: bold;\n  color: #344054;\n  background: #ffffff;\n  border: 1px solid #e4e7ec;\n  border-radius: 6px;\n  cursor: pointer;\n  transition: all 0.3s ease;\n\n\n  &.outline {\n    color: $primary-color;\n    background-color: transparent;\n    border: 1px solid $primary-color;\n\n    &:hover {\n      background-color: $primary-color;\n      color: #000;\n    }\n  }\n\n  &.danger {\n    background-color: #dc3545;\n\n    &:hover {\n      background-color: darken(#dc3545, 10%);\n    }\n  }\n\n  &.small {\n    padding: 6px 12px;\n    font-size: 14px;\n  }\n\n  &.large {\n    padding: 12px 24px;\n    font-size: 18px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
