import { useState, useEffect } from "react";
import { column } from "./data.js";
import Button from "../../components/Button/Button";
import "./message.scss";
import { Pagination } from "antd";
import MessageTable from "../../components/MessageTable/MessageTable";
import jwtDecode from "jwt-decode";
import { BASE_URL } from "../../context/base/base";
import axios from "axios";
import { DatePicker } from "antd";
import { useQuery } from "@tanstack/react-query";
import Select from "react-select";
import { toast } from "react-toastify";

const options = [
  { value: "Delivered", label: "Delivered" },
  { value: "Pending", label: "Pending" },
  { value: "Expired", label: "Expired" },
  { value: "Undelivered", label: "Undelivered" },
  { value: "Rejected", label: "Rejected" },
  { value: "Unknown", label: "Unknown" },
];

export default function Message() {
  const { RangePicker } = DatePicker;

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [msisdn, setMsisdn] = useState("");
  const [status, setStatus] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [dateRange, setDateRange] = useState([]);

  const handleQueryChange = (e) => {
    setMsisdn(e.target.value);
  };

  const userDetail = localStorage.getItem("user");
  const user = JSON.parse(userDetail);

  const token = user?.access_token;

  if (token) {
    const decodedToken = jwtDecode(token);
    if (decodedToken.exp * 1000 < new Date().getTime()) {
      localStorage.removeItem("user");
      window.location.href = "/login";
    }
  }

  const handleSelectChange = (selectedOption) => {
    const actualValue = selectedOption.value;
    console.log("selectedOption", selectedOption.value);
    setSelectedOption(actualValue);
    if (actualValue === "Delivered") {
      setStatus("DELIVRD");
    } else if (actualValue === "Pending") {
      setStatus("PENDING");
    } else if (actualValue === "Expired") {
      setStatus("EXPIRED");
    } else if (actualValue === "Undelivered") {
      setStatus("FAILED");
    } else if (actualValue === "Rejected") {
      setStatus("REJECTED");
    } else if (actualValue === "Unknown") {
      setStatus("UNKNOWN");
    } else {
      setStatus("");
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDateRangeChange = (dates, dateString) => {
    setDateRange(dateString);
  };

  const getMessagePage = async () => {
    let url;

    if (status || msisdn) {
      if (msisdn && dateRange.length) {
        url = `${BASE_URL}/auth/message_log?page=${currentPage}&page_size=${pageSize}&msisdn=${msisdn}&status=${status}&start_date=${dateRange[0]}&end_date=${dateRange[1]}`;
      }
      if (msisdn && dateRange.length === 0) {
        url = `${BASE_URL}/auth/message_log?page=${currentPage}&page_size=${pageSize}&msisdn=${msisdn}&status=${status}&start_date=&end_date=`;
      }
    } else {
      url = `${BASE_URL}/auth/message_log?page=${currentPage}&page_size=${pageSize}&default_log=default`;
      setMsisdn("");
    }

    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Frame-Options": "deny",
        "Access-Control-Allow-Origin": "*",
        "Content-Security-Policy": "script-src https: 'unsafe-inline' 'unsafe-eval';style-src https: 'unsafe-inline' 'unsafe-eval';img-src https: data:;font-src https: data:;"
      },
    });
    return res?.data;
  };

  const { isLoading, error, data } = useQuery(
    ["getMessagePage", currentPage, pageSize, msisdn, status, dateRange],
    getMessagePage,
    {
      select: (msgData) => {
        let recentReport = msgData?.data;
        let totalCount = msgData?.total_count;
        return { recentReport, totalCount };
      },
      staleTime: 10 * 60 * 1000,
    }
  );

  if (error) {
    toast.error(error.message);
  }

  const actualData = data?.recentReport;
  const totalNum = data?.totalCount;

  useEffect(() => {
    setCurrentPage(1);
  }, [msisdn]);

  const formatTime = (output) => {
    const date = new Date(output);
    const options = {
      day: "numeric",
      month: "short",
      year: "numeric",
    };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );
    return formattedDate;
  };

  const handleDownload = () => {
    const headers = {
      Message: "Message",
      Recipient: "Recipent",
      Network: "Network",
      SenderID: "Sender-ID",
      Unit: "Unit",
      SentTime: "Sent-Time",
      DeliveryTime: "Delivery-Time",
      Status: "Status",
    };

    let csvContent = "data:text/csv;charset=utf-8,";

    csvContent += Object.values(headers).join(",") + "\n";

    //  Add the data
    actualData?.forEach((item) => {
      const formattedData = {
        Message: item?.Message,
        Recipient: item?.Recipent,
        Network: item?.Network?.split("_")[0],
        SenderID: item?.SenderID,
        Unit: item?.Unit,
        SentTime: formatTime(item?.SentTime?.split("T")[0]) || "-",
        DeliveryTime: formatTime(item?.DeliveryTime?.split("T")[0]) || "-",
        Status: item?.Status,
      };
      csvContent +=
        Object.values(formattedData)
          .map((value) => `"${value}"`)
          .join(",") + "\n";
    });

    // Create a hidden link and click it to download the file
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "message-log.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="msglog">
      <div className="message">
        <div className="messageTop__left">
          <div className="search-field">
            <input
              type="text"
              placeholder="Search phone number"
              value={msisdn}
              onChange={handleQueryChange}
            />
          </div>
        </div>
        <div className="messageTop__right">
          <div className="calenderView">
            <div className="calenderBtn">
              {msisdn && (
                <RangePicker
                  style={{ height: "39px", width: "200px" }}
                  size="large"
                  onChange={handleDateRangeChange}
                />
              )}
            </div>
          </div>

          {msisdn && (
            <div className="status">
              <Select
                className="select222"
                defaultValue={selectedOption}
                onChange={handleSelectChange}
                options={options}
              />
            </div>
          )}

          <div className="downloadLog">
            {msisdn?.length > 0 && (
              <Button onClick={handleDownload} className="button">
                Download log
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="table">
        <MessageTable column={column} data={actualData} loading={isLoading} />
      </div>
      <div className="paginate">
        <Pagination
          total={totalNum}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          pageSize={pageSize}
          current={currentPage}
          onChange={handlePageChange}
          showSizeChanger={false}
        />
      </div>
    </div>
  );
}
