import "./report.scss";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import jwtDecode from "jwt-decode";
import { BASE_URL } from "../../context/base/base";
import "jspdf-autotable";
import ReportTable from "../../components/ReportTable/ReportTable";
import { Pagination } from "antd";
import Button from "../../components/Button/Button";
import { useState, useEffect } from "react";
import { DatePicker } from "antd";
import commaNumber from "comma-number";
import { toast } from "react-toastify";

export default function Report() {
  const { RangePicker } = DatePicker;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedOption, setSelectedOption] = useState("1");
  const [dateRange, setDateRange] = useState([]);
  const [yearData, setYearData] = useState("2023");
  const [yearValue, setYearValue] = useState();

  const userDetail = localStorage.getItem("user");
  const user = JSON.parse(userDetail);

  const token = user?.access_token;

  if (token) {
    const decodedToken = jwtDecode(token);
    if (decodedToken.exp * 1000 < new Date().getTime()) {
      localStorage.removeItem("user");
      window.location.href = "/login";
    }
  }

  const getReportPage = async () => {
    let url;
    if (selectedOption === "1") {
      if (!dateRange || !dateRange.length) {
        url = `${BASE_URL}/auth/report?type=Daily&start_date=&end_date=`;
      } else {
        url = `${BASE_URL}/auth/report?type=Daily&start_date=${dateRange[0]}&end_date=${dateRange[1]}`;
      }
    } else if (selectedOption === "2") {
      if (!yearData) {
        url = `${BASE_URL}/auth/report?type=Monthly&year=`;
      } else {
        url = `${BASE_URL}/auth/report?type=Monthly&year=${yearData.slice(
          0,
          4
        )}`;
      }
    }

    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Frame-Options": "deny",
        "Access-Control-Allow-Origin": "*",
        "Content-Security-Policy": "script-src https: 'unsafe-inline' 'unsafe-eval';style-src https: 'unsafe-inline' 'unsafe-eval';img-src https: data:;font-src https: data:;"
      },
    });

    return res;
  };

  const { isLoading, error, data } = useQuery(
    ["getReportPage", selectedOption, dateRange, yearData],
    getReportPage,
    {
      select: (reportData) => {
        let recentReport = reportData?.data?.data;

        return recentReport;
      },
      staleTime: 10 * 60 * 1000,
    }
  );

  if (error) {
    toast.error(error.message);
  }
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
    if (e.target.value === "2") {
      setDateRange([]);
    }
    if (e.target.value === "1") {
      setYearData("");
    }
  };

  const handleDateRangeChange = (dates, dateString) => {
    setDateRange(dateString);
  };
  const handleMonthChange = (date, dateString) => {
    setYearData(dateString);
  };
  const disabledDate = (current) => {
    return current && current.year() < 2022;
  };

  const formatTime = (output) => {
    const date = new Date(output);
    const options = {
      day: "numeric",
      month: "short",
      year: "numeric",
    };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );
    return formattedDate;
  };
  const handleDownload = () => {
    const headers = {
      date: "Date",
      mtn: "MTN(Units)",
      mtn2: "MTN(Amount)",
      nineMobile: "9Mobile(Units)",
      nineMobile2: "9Mobile(Amount)",
      airtel: "Airtel(Units)",
      airtel2: "Airtel(Amount)",
      glo: "GLO(Units)",
      glo2: "GLO(Amount)",
      totalUnits: "Total Units",
      totalAmount: "Total Amount",
    };

    let csvContent = "data:text/csv;charset=utf-8,";

    // Add the headers
    csvContent += Object.values(headers).join(",") + "\n";

    // Add the data
    data?.forEach((item) => {
      const formattedData = {
        date: formatTime(item?.date),
        mtn: `${commaNumber(item?.mtn_count) || 0}`,
        mtn2: `₦${commaNumber(item?.mtn_cost) || 0}`,
        nineMobile: `${commaNumber(item?.gmobile_count) || 0}`,
        nineMobile2: `₦${commaNumber(item?.gmobile_cost) || 0}`,
        airtel: `${commaNumber(item?.airtel_count) || 0}`,
        airtel2: `₦${commaNumber(item?.airtel_cost) || 0}`,
        glo: `${commaNumber(item?.glo_count) || 0}`,
        glo2: `₦${commaNumber(item?.glo_cost) || 0}`,
        totalUnits: commaNumber(item?.total_count),
        totalAmount: `₦ ${commaNumber(item?.total_cost)}`,
      };
      csvContent +=
        Object.values(formattedData)
          .map((value) => `"${value}"`)
          .join(",") + "\n";
    });

    // Create a hidden link and click it to download the file
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      `${selectedOption === "1" ? "Daily" : "Monthly"} Report ${
        dateRange.length
          ? `for ${formatTime(dateRange[0])} to ${formatTime(dateRange[1])}`
          : ""
      }  ${yearData.length ? `for ${yearData.slice(0, 4)}` : ""}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const pagedData = data?.slice(startIndex, endIndex);

  let isMonthSelected = false;

  if (selectedOption === "2") {
    isMonthSelected = true;
  }

  useEffect(() => {
    if (yearData === "") {
      setYearValue("2023");
    } else {
      setYearValue(yearData);
    }
  }, [yearData]);

  return (
    <>
      <div className="reportTable">
        <div className="report">
          <div className="reportTop__left">
            <div className="wrap">
              <div className="select">
                <label className="select-label">Summary Type : </label>
                <select
                  className="select-text"
                  required
                  value={selectedOption}
                  onChange={handleSelectChange}
                >
                  <option value="1">Daily</option>
                  <option value="2">Monthly</option>
                </select>
                <span className="select-highlight"></span>
                <span className="select-bar"></span>
              </div>
            </div>
          </div>
          <div className="reportTop__right">
            <div className="calenderView">
              <div className="calenderBtn">
                {selectedOption === "1" && (
                  <div className="calenderRange">
                    <RangePicker
                      size="large"
                      style={{ height: "39px", width: "200px" }}
                      onChange={handleDateRangeChange}
                    />
                  </div>
                )}
                {selectedOption === "2" && (
                  <div className="calenderRange">
                    <DatePicker
                      onChange={handleMonthChange}
                      size="large"
                      style={{ height: "39px", width: "200px" }}
                      picker="year"
                      disabledDate={disabledDate}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="downloadReport">
              <Button onClick={handleDownload} className="button">
                Download Report
              </Button>
            </div>
          </div>
        </div>

        <div>
          <table id="my-table">
            <ReportTable
              data={pagedData}
              loading={isLoading}
              isMonthSelected={isMonthSelected}
              yearValue={yearValue}
            />
          </table>
        </div>
        <div className="paginate">
          <Pagination
            total={data?.length}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            pageSize={pageSize}
            current={currentPage}
            onChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
}
