// export const data = [
//         {
//             "Message":"<nairapool>your capcha is 1497.",
//             "Network": "Airtel_Transactional",
//             "Recipent": "2347016054269",
//             "Sender ID": "LISKCOM",
//             "Sent-time - Delivery-Time": [
//                 "2023-03-07T02:54:52.113",
//                 "2023-03-07T02:54:58.154"
//             ],
//             "Status": [
//                 "DELIVRD",
//                 "000"
//             ],
//             "Unit": "1"
//         },
//         {
//             "Message":"<nairapool>your capcha is 1497.",
//             "Network": "Airtel_Transactional",
//             "Recipent": "2347016054269",
//             "Sender ID": "LISKCOM",
//             "Sent-time - Delivery-Time": [
//                 "2023-03-07T02:54:52.113",
//                 "2023-03-07T02:54:58.154"
//             ],
//             "Status": [
//                 "DELIVRD",
//                 "000"
//             ],
//             "Unit": "1"
//         },
//         {
//             "Message":"<nairapool>your capcha is 1497.",
//             "Network": "Airtel_Transactional",
//             "Recipent": "2347016054269",
//             "Sender ID": "LISKCOM",
//             "Sent-time - Delivery-Time": [
//                 "2023-03-07T02:54:52.113",
//                 "2023-03-07T02:54:58.154"
//             ],
//             "Status": [
//                 "DELIVRD",
//                 "000"
//             ],
//             "Unit": "1"
//         },
//         {
//             "Message":"<nairapool>your capcha is 1497.",
//             "Network": "Airtel_Transactional",
//             "Recipent": "2347016054269",
//             "Sender ID": "LISKCOM",
//             "Sent-time - Delivery-Time": [
//                 "2023-03-07T02:54:52.113",
//                 "2023-03-07T02:54:58.154"
//             ],
//             "Status": [
//                 "DELIVRD",
//                 "000"
//             ],
//             "Unit": "1"
//         },
//         {
//             "Message":"<nairapool>your capcha is 1497.",
//             "Network": "Airtel_Transactional",
//             "Recipent": "2347016054269",
//             "Sender ID": "LISKCOM",
//             "Sent-time - Delivery-Time": [
//                 "2023-03-07T02:54:52.113",
//                 "2023-03-07T02:54:58.154"
//             ],
//             "Status": [
//                 "DELIVRD",
//                 "000"
//             ],
//             "Unit": "1"
//         },
//         {
//             "Message":"<nairapool>your capcha is 1497.",
//             "Network": "Airtel_Transactional",
//             "Recipent": "2347016054269",
//             "Sender ID": "LISKCOM",
//             "Sent-time - Delivery-Time": [
//                 "2023-03-07T02:54:52.113",
//                 "2023-03-07T02:54:58.154"
//             ],
//             "Status": [
//                 "DELIVRD",
//                 "000"
//             ],
//             "Unit": "1"
//         },
//         {
//             "Message":"<nairapool>your capcha is 1497.",
//             "Network": "Airtel_Transactional",
//             "Recipent": "2347016054269",
//             "Sender ID": "LISKCOM",
//             "Sent-time - Delivery-Time": [
//                 "2023-03-07T02:54:52.113",
//                 "2023-03-07T02:54:58.154"
//             ],
//             "Status": [
//                 "DELIVRD",
//                 "000"
//             ],
//             "Unit": "1"
//         }
//     ];

export const column = [
  { id: 'Message', label: 'Message', minWidth: 100 },
  { id: 'Recipient', label: 'Recipient', minWidth: 100 },
  { id: 'Network', label: 'Network', minWidth: 100 },
  {
    id: 'SenderID',
    label: 'Sender ID',
    minWidth: 100,
  },
  {
    id: 'Unit',
    label: 'Unit',
    minWidth: 150,
  },
  {
    id: 'SentTime-DeliveryTime',
    label: 'Sent time - Delivery time',
    minWidth: 80,
  },
  {
    id: 'Status',
    label: 'Status',
    minWidth: 150,
  },
]
