import { ColorRing } from "react-loader-spinner";

export const Loader = () => {
  return (
    <ColorRing
      height="50"
      width="50"
      color="#4094f7"
      ariaLabel="blocks-loading"
      wrapperStyle={{}}
      wrapperClass="blocks-wrapper"
      colors={["#4285f4", "#64b5f6", "#90caf9", "#b0bec5", "#78909c"]}
    />
  );
};
